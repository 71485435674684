//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------
@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";
a {
    text-decoration: none;
}

a,
p {
    font-family: "Public Sans", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

h1 {
    font-family: "Public Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 25px;
    text-transform: uppercase;
}
h2 {
    font-family: "DIN 1451 Std", Arial, sans-serif;
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

/* h3 {
    font-family: "DIN 1451 Std", Arial, sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
} */

.slash_symbol {
    font-family: "DIN 1451 Std", Arial, sans-serif;
    color: #98f5c4;
    letter-spacing: 0.1em;
}

.btn {
    text-transform: uppercase;
}

.card {
    font-size: 24px;
    line-height: 36px;
    padding: 20px;
}

cite {
    font-family: "Public Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

.btn {
    --bs-btn-color: #073a5f;
    --bs-btn-bg: #98f5c4;
    --bs-btn-border-radius: 50px;
    border: 0px #98f5c4;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-family: "Public Sans", Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    padding: 15px 30px 15px 30px;
    text-transform: none;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 500%;
        height: 1000%;
        background: #073a5f;
        z-index: -1;
        transform-origin: 0% 0%;
        transform: translateX(calc(20% - 25px)) translateY(10%) rotate(-45deg);
        transform: translateY(10%) translateX(20%) rotate(-60deg);
        transition: transform 0.15s;
    }
    &:hover {
        color: #ffffff;
        border: 0px #073a5f;
        box-shadow:
            0px 1px 0px 0px #1b1f231a,
            0px 4px 20px 0px #00000033,
            0px 2px 2px 0px #00000026;
        &::after {
            transform: translateY(10%) translateX(-40px) rotate(-60deg);
        }
    }
}
.btn-light {
    background-color: white;
    color: $primary;
}
.image_half_size {
    width: 50%;
    height: 50%;
}

#fixed_contact_button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: opacity 0.5s;
}

.bg-lightgray {
    background-color: $lightgray;
}

.modal-header {
    h2 {
        line-height: normal;
        margin-bottom: -7px;
    }
    box-shadow: 0px 4px 30px 0px #0000001a;
    border-bottom-color: #1d1d1b45;
}

.modal-header,
.modal-body {
    padding: 40px 40px 40px;
    @include media-breakpoint-down(md) {
        padding: 30px 30px 30px;
    }
}

.btn-close {
    height: 0.25rem;
    width: 0.25rem;
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.40799 6.00019L11.708 1.71019C11.8963 1.52188 12.0021 1.26649 12.0021 1.00019C12.0021 0.733884 11.8963 0.478489 11.708 0.290185C11.5197 0.101882 11.2643 -0.00390625 10.998 -0.00390625C10.7317 -0.00390625 10.4763 0.101882 10.288 0.290185L5.99799 4.59019L1.70799 0.290185C1.51968 0.101882 1.26429 -0.00390625 0.997986 -0.00390625C0.731684 -0.00390625 0.47629 0.101882 0.287986 0.290185C0.0996821 0.478489 -0.0061059 0.733884 -0.0061059 1.00019C-0.0061059 1.26649 0.0996821 1.52188 0.287986 1.71019L4.58799 6.00019L0.287986 10.2902C0.194257 10.3831 0.119863 10.4937 0.0690947 10.6156C0.018326 10.7375 -0.0078125 10.8682 -0.0078125 11.0002C-0.0078125 11.1322 0.018326 11.2629 0.0690947 11.3848C0.119863 11.5066 0.194257 11.6172 0.287986 11.7102C0.380949 11.8039 0.49155 11.8783 0.613409 11.9291C0.735268 11.9798 0.865974 12.006 0.997986 12.006C1.13 12.006 1.2607 11.9798 1.38256 11.9291C1.50442 11.8783 1.61502 11.8039 1.70799 11.7102L5.99799 7.41019L10.288 11.7102C10.3809 11.8039 10.4915 11.8783 10.6134 11.9291C10.7353 11.9798 10.866 12.006 10.998 12.006C11.13 12.006 11.2607 11.9798 11.3826 11.9291C11.5044 11.8783 11.615 11.8039 11.708 11.7102C11.8017 11.6172 11.8761 11.5066 11.9269 11.3848C11.9776 11.2629 12.0038 11.1322 12.0038 11.0002C12.0038 10.8682 11.9776 10.7375 11.9269 10.6156C11.8761 10.4937 11.8017 10.3831 11.708 10.2902L7.40799 6.00019Z' fill='%23486284'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;

    &:focus {
        outline: 0;
        box-shadow: unset;
    }
}

.underline_animation {
    display: inline-block;
    position: relative;
    color: $primary;
    text-decoration: none;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        border-radius: 5px;
        height: 0.05em;
        bottom: 0;
        left: 0;
        background: currentcolor;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

.bg-nightblue {
    background-color: $nightblue;
}

.modal-backdrop.show {
    opacity: 0.7;
}

/* Accordion "+" "-" icons */
.accordion-button {
    &::after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
        /* transition: all 0.5s; */
    }
}
.accordion-button.collapsed {
    &:hover::after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='white' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !important;
        /* transition: all 0.5s; */
    }
}
.accordion-button:not(.collapsed) {
    &::after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
    }
}

.disabled {
    cursor: default !important;
    &::after {
        display: none;
    }
}

.container-xxxl {
    max-width: 1600px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(1.5rem * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(1.5rem * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.slogan_text {
    font-family: "Public Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
}

#fixed_signet {
    right: unset;
    left: unset;
    top: -100px;
    transition: top 0.6s;
    @include media-breakpoint-down(md) {
        img {
            width: 60px;
        }
    }
    &.active {
        top: 10px;
        transition: top 0.6s;

        @include media-breakpoint-down(md) {
            top: -10px;
        }
    }
}

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

section:not(.no-padding) {
    padding-top: $sectionPaddingTop;
    padding-bottom: $sectionPaddingBottom;
    @include media-breakpoint-down(lg) {
        padding-top: calc($sectionPaddingTop / 1.5);
        padding-bottom: calc($sectionPaddingBottom / 1.5);
    }
    @include media-breakpoint-down(md) {
        padding-top: calc($sectionPaddingTop / 2);
        padding-bottom: calc($sectionPaddingBottom / 2);
    }
}
footer {
    .language_switcher {
        width: 104px;
    }
}
.language_switcher {
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a,
    span {
        font-family: "DIN 1451 Std", Arial, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
    }
}

#hero-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;

    @include media-breakpoint-down(lg) {
        .navbar-brand img {
            max-width: 160px;
        }
        @media (orientation: landscape) {
            padding-top: 0px;
        }
    }
}

#sticky-heros {
    .content {
        padding: var(--page-padding);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content--sticky {
        width: 100%;
        position: sticky;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        background-blend-mode: multiply;
        top: 0;
        --offset: 0px;
        top: var(--offset);
        height: calc(100vh - var(--offset));

        /* .content__title {
        font-family: "Public Sans", Arial, sans-serif;
        color: #124a72;
        grid-area: content-title;
        font-weight: 700;
        font-size: 70px;
        line-height: 110%;
        text-transform: uppercase;
        margin: 0;
        } */

        > .container {
            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 30%;
                @media (orientation: landscape) {
                    position: relative;
                    top: unset;
                }
            }
        }

        svg.triple-fan-text {
            max-width: 70vw;
            .animate {
                opacity: 1; /* Initially set opacity to 0 */
            }
            @include media-breakpoint-down(lg) {
                @media (orientation: landscape) {
                    max-width: 50vw;
                }
            }
        }

        .content__text {
            color: #124a72;
            font-weight: 400;
            font-size: 32px;
            line-height: 38.4px;
            margin: 0;
            margin-top: 46px;
            backface-visibility: hidden;
            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 27px;
                margin-top: 9px;
            }
        }

        .btn {
            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 20%;
            }
            @include media-breakpoint-down(lg) {
                margin-top: 43px;
                @media (orientation: landscape) {
                    margin-top: 30px;
                }
            }
        }

        &.content--one{
            background-color: #b6d6f1;
        }
        &.content--two {
            background-color: #dbfcec;
        }

        &.content--one,&.content--two {
            /* background-size: auto;
            background-position: calc(100vw - 700px) center; */
            @include media-breakpoint-up(md) {
                .content__text {
                    max-width: 400px;
                }
                background-size: clamp(320px, 80%, 1100px) auto;
            }
            @media (orientation: landscape) {
                /* background-size: clamp(320px, 70%, 100%) auto; */
                background-size: contain;
            }
            
            @include media-breakpoint-down(md) {
                .content__text {
                    max-width: 70vw;
                }
                background-size: 170%;
                background-position: right bottom;
                @media only screen and (max-height : 650px) {
                    /* background-size: clamp(320px, 70%, 100%) auto; */
                    background-size: contain;
                }
            }
        }
        
        &.content--three {
            background-size: cover;
            background-color: #f0e3d6;
            @include media-breakpoint-up(md) {
                .content__text {
                    max-width: 500px;
                }
            }
            @include media-breakpoint-down(md) {
                .content__text {
                    max-width: 70vw;
                }
                background-position: center;
            }
        }
    }
}

#our-story {
    h3 {
        font-size: 70px;
        font-weight: 700;
        line-height: 77px;
        margin-top: -20px;
        @include media-breakpoint-down(md) {
            font-size: 50px;
            line-height: 57px;
        }
    }
    p {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        @include media-breakpoint-down(md) {
            font-size: 22px;
            line-height: 38px;
        }
    }
}

@include media-breakpoint-up(md) {
    .overlap-to-left-md.active {
        margin-left: -20rem !important;
    }
}

.swiper {
    width: 100%; /* 
    height: 300px; */
}

/* #parallax_hero{
    width:100%;
  height:100%;
.scroll_dist,
.parallax_main{
    position:absolute;
  }
} */

#quote {
    padding-top: 0px;

    blockquote::before {
        content: "“";
        left: -10px;
        top: 2rem;
        position: relative;
    }
}
#solutions,
#manufacturing {
    h4 {
        font-family: "DIN 1451 Std", Arial, sans-serif;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
}

#what-sets-us {
    @include media-breakpoint-up(lg) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .accordion-button {
        color: $dark;
    }
    h3 {
        font-size: 50px;
        font-weight: 700;
        line-height: 55px;
    }
    .row > div:first-child {
        padding-top: $sectionPaddingTop;
        padding-bottom: $sectionPaddingBottom;
        @include media-breakpoint-down(lg) {
            padding-top: calc($sectionPaddingTop / 1.5);
            padding-bottom: calc($sectionPaddingBottom / 1.5);
        }
        @include media-breakpoint-down(md) {
            padding-top: calc($sectionPaddingTop / 2);
            padding-bottom: calc($sectionPaddingBottom / 2);
        }
    }
    .accordion-horizontal {
        height: 100%;
    }
    .accordion-body p {
        padding-top: 30px;
    }
    .title {
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
    }
    p {
        display: block;
        margin: 0;
        padding-bottom: 30px;
        font-size: 1.125em;
        line-height: 1.5em;
        letter-spacing: 1px;
        // border-left: 4px solid white;
        font-weight: 500;
    }
    .accordion-cell img {
        width: 100%;
    }
    .accordion-button.collapsed {
        &:hover {
            color: white;
        }
    }
    img {
        transition: width 0.25s;
    }
}

#skills {
    svg {
        overflow: visible;
        text {
            display: inline-block;
            position: relative;
            color: $primary;
            text-decoration: none;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                border-radius: 5px;
                height: 0.05em;
                bottom: 0;
                left: 0;
                background: currentcolor;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }
            &:hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
        #circles {
            > g {
                circle {
                    transform: scale(1);
                    transform-origin: center center;
                    transition: all 0.5s;
                    z-index: -1;
                    position: relative;
                    &.active {
                        fill: transparent;
                        stroke: white;
                        stroke-width: 2;
                        transform: scale(1.1);
                        transform-origin: center;
                        transition: all 0.5s;
                    }
                }
            }
            tspan {
                cursor: pointer;
                font-family: "DIN 1451 Std", Arial, sans-serif;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 5px;
                z-index: 999;
                position: absolute;
                &.arrow_icon {
                    font-family: "icomoon", Arial, sans-serif;
                    font-size: 0.8rem;
                }
            }
            #consulting circle.active {
                transform-origin: 40% 30%;
            }
            #storage_and_shipping circle,
            #storage_and_shipping circle.active {
                transform-origin: 70% 70%;
            }
        }
        .st5 {
            fill: none;
            stroke: #181716;
            stroke-width: 2;
            stroke-miterlimit: 10;
        }
    }
}

.accordion-horizontal {
    display: flex;
    /* height: 100vh; */
    line-height: 0;
    // overflow: hidden;
    // white-space: nowrap;
}

$cellWidth: 90px;

.accordion-cell {
    display: inline-block;
    position: relative;
    /* height: 100vh; */
    width: $cellWidth;
    transition-property: width;
    transition-duration: 250ms;
    background-color: $secondary;
    // white-space: normal;
    // transition-timing-function: cubic-bezier(1,0,0,1);
    &:nth-child(1) {
        border-left: 2px solid white;
    }
    border-right: 2px solid white;

    svg.icon-plus {
        cursor: pointer;
    }
    & > h4 {
        font-size: 30px;
        font-weight: 700;
        display: block;
        position: absolute;
        width: $cellWidth;
        margin: 0;
        padding: 0;
        left: -0rem;
        top: 10rem;
        direction: rtl;
        text-align: left;
        transform: rotate(-90deg);
        transition-property: left right transform;
        transition-duration: 250ms;
    }
    & > svg {
        cursor: pointer;
        display: block;
        position: absolute;
        width: 30px;
        margin: 0;
        padding: 0;
        left: -0rem;
        top: 26px;
        transform: rotate(-90deg) translateY(26px);
        transition-property: left right opacity transform;
        transition-duration: 250ms;
    }
    & > .accordion-cell-content {
        display: block;
        width: 100%;
        max-height: 800px;
        margin: 0;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 250ms;
        // transition-delay: 250ms;
        overflow: hidden;
        & > .title {
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
        }
        & > p {
            display: block;
            margin: 0;
            padding: 30px;
            font-size: 1.125em;
            line-height: 1.5em;
            letter-spacing: 1px;
            // border-left: 4px solid white;
            font-weight: 500;
        }
        img {
            width: 100%;
        }
    }
    &.collapsed {
        cursor: pointer;
        &:hover {
            color: white;
            svg rect {
                fill: white;
            }
            background: linear-gradient(270deg, #073a5f -57.38%, rgba(0, 32, 62, 0) 98.39%),
                linear-gradient(0deg, #124a72, #124a72);
        }
    }
}

.accordion-item {
    border-color: #124a72;
    background-color: $lightgray;
    button {
        position: relative;
        overflow: hidden;
        z-index: 1;
        background-color: $lightgray;
        transition:
            transform 0.25s,
            padding-left 0.5s;
        &:focus {
            box-shadow: unset;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 500%;
            height: 1800%;
            background: linear-gradient(270deg, #073a5f -57.38%, rgba(0, 32, 62, 0) 98.39%), #124a72;
            z-index: -1;
            transform-origin: 0% 0%;
            transform: translateX(calc(20% - 25px)) translateY(10%) rotate(-45deg);
            transform: translateY(10%) translateX(20%) rotate(-60deg);
            transition: transform 0.25s;
        }
        &:hover {
            padding-left: 20px;
            border: 0px #98f5c4;
            transition: padding-left 0.5s;
            &::before {
                transform: translateY(7%) translateX(-100px) rotate(-60deg);
            }
        }
    }
    .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: unset;
        &::before {
            background: transparent;
        }
        &:hover {
            &::before {
                background: transparent;
            }
        }
    }

    h2 {
        button {
            font-family: "Public Sans", Arial, sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
        }
    }
    .accordion-body {
        font-family: "Public Sans", Arial, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    }
}

.expanded {
    width: 100%;
    & > {
        h4 {
            opacity: 0;
            /* left: 1rem;
            bottom: 1rem;
            transform: rotate(0deg); */
        }
        svg {
            cursor: pointer;
            left: unset;
            right: 3.6rem;
        }
        svg > rect:first-child {
            opacity: 0;
            transition: opacity 0.25s;
        }
    }
    & > .accordion-cell-content {
        opacity: 1;
        transition-property: opacity;
        transition-duration: 250ms;
        // transition-delay: 250ms;
    }

    .collapsed {
        width: $cellWidth;
    }
}

#products {
    a,
    h5 {
        font-family: "Public Sans", Arial, sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        text-decoration: none !important;
    }
    .card {
        position: relative;
        transform: scale(1);
        transition: all 0.15s ease-out;
        @include media-breakpoint-up(md) {
            &:hover {
                transform: scale(1.1);
                &::before {
                    opacity: 1;
                    transition: all 0.15s ease-out;
                }
            }
        }

        &::before {
            opacity: 0;
            z-index: -1;
            content: "";
            width: 100%;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            border-radius: 5px;
            transition: all 0.15s ease-out;
            background: linear-gradient(180deg, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%),
                linear-gradient(0deg, #ffffff, #ffffff);
        }
    }
    .card-img-top {
        align-self: center;
        display: flex;
        max-width: 200px;
    }
}

.fixed_background_image {
    min-height: 310px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @supports (-webkit-touch-callout: inherit) {
        background-attachment: scroll !important;
    }
    position: relative;

    &::after {
        width: 0;
        height: 0;
        border-bottom: 310px solid white;
        border-left: 180px solid transparent;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
    }
}
.background-image::before {
    content: " ";
    display: block;
    //hier den Pfad zu deinem Hintergrundbild einsetzen
    background-image: url("/images/pgh_fixed_backgound-image.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: -10; // Das hier ist sehr wichtig!
}

#facts-and-stats {
    .fact_value {
        padding-top: 22px;
        font-family: "Public Sans", Arial, sans-serif;
        color: #073a5f;
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 0px;
        @include media-breakpoint-down(lg) {
            font-size: 36px;
        }
    }
    .fact_description {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: #124a72;
    }
}

#product-highlights-and-innovations {
    h3 {
        font-family: "DIN 1451 Std", Arial, sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
}

#complete-overview {
    h3 {
        font-family: "DIN 1451 Std", Arial, sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
    .row {
        padding: 3rem;
    }
}

#faq {
    @include media-breakpoint-up(md) {
        .accordion {
            margin-top: -15px;
        }
    }
    .accordion-item {
        button {
            padding-left: 0px;
            &:hover {
                color: #ffffff;
                padding-left: 10px;
            }
        }
        h2 {
            button {
                color: #1d1d1b;
            }
        }
        .accordion-button:not(.collapsed) {
            &:hover {
                padding-left: 0px;
            }
        }
        .accordion-body {
            padding-left: 0px;
            font-family: "Public Sans", Arial, sans-serif;
            color: #1d1d1b;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
        }
    }
    .accordion-button:not(.collapsed) {
        &:hover {
            color: #1d1d1b;
        }
    }
}

#clients {
    .swiper-slide:not(:last-child) {
        /* border-right: 1px solid #cedbe4; */
        &::after {
            content: "";
            position: absolute;
            background-color: #cedbe4;
            width: 1px;
            height: 100%;
            top: 0px;
            left: -30px;
        }
    }
    .swiper-slide {
        justify-content: center;
        display: flex;
        @include media-breakpoint-down(sm) {
            img {
                max-height: 200px;
            }
        }
    }
}

#contact {
    a.contact_info {
        display: flex;
        align-items: center;
        img.icon {
            height: 1.2rem;
            margin-right: 10px;
        }
    }
    @include media-breakpoint-up(md) {
        .btn {
            margin-top: 6px;
        }
    }
}

.footer_section {
    footer {
        padding-top: 30px;
        padding-bottom: 170px;
        @include media-breakpoint-down(md) {
            padding-bottom: 85px;
        }
        .copyright {
            font-family: "DIN 1451 Std", Arial, sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0.05em;
            text-align: left;
        }
        p,
        a,
        .underline_animation {
            color: white !important;
        }
        nav a span {
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
        }
    }
}
#consulting-modal {
    .modal-header,
    .modal-body {
        padding: 40px 26px 40px;
        @include media-breakpoint-down(md) {
            padding: 30px 26px 30px;
        }
    }
}
#more-products-modal {
    a {
        img {
            max-width: 70px;
            transform: scale(1);
            transition: all 0.15s ease-out;
        }
        p {
            font-family: "Public Sans", Arial, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            margin-left: 10px;
            margin-bottom: 0px;
        }
        &:hover {
            img {
                transform: scale(1.1);
                transition: all 0.15s ease-out;
            }
        }
    }
    .modal-body .row > div {
        margin-bottom: 20px;
    }
}

.social_media_links {
    padding-top: 20px;
    li {
        &:first-child {
            margin-left: -0.5rem;
        }
        transition: all 0.25s;
        width: 44px;
        height: 44px;
        text-align: center;
    }
    li:hover {
        background-color: white;
        transition: all 0.25s;
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

/* @import "components/slider"; */

/* boostrap container only one side fluid */
@mixin make-container-fluid-left-right($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: calc($gutter / 2);
    padding-left: calc($gutter / 2);
}
@mixin make-container-fluid-left($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            margin-right: calc((100% - #{$container-max-width}) / 2);
            width: calc(100% - ((100% - #{$container-max-width}) / 2));
        }
    }
}
@mixin make-container-fluid-right($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            margin-left: calc((100% - #{$container-max-width}) / 2);
            width: calc(100% - ((100% - #{$container-max-width}) / 2));
        }
    }
}
.container-fluid-left {
    @include make-container-fluid-left-right();
    @include make-container-fluid-left();
}
.container-fluid-right {
    @include make-container-fluid-left-right();
    @include make-container-fluid-right();
}


/*Cookiebot*/
#CybotCookiebotDialog,
#CookiebotWidget.CookiebotWidget-open,
#CookiebotWidget.CookiebotWidget-open #CookiebotWidget-widgetContent {
  border-radius: 50px !important;
}
#CybotCookiebotDialogPoweredbyLink,
#CybotCookiebotDialogPoweredbyCybot,
.CookiebotWidget-main-logo {
  display: none !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
  #CookiebotWidget-btn-change {
  background-color: #98f5c4 !important;
  border-color: #98f5c4 !important;
  color: #073a5f !important;
  border-radius: 50px !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  background-color: transparent !important;
  border-color: #98f5c4 !important;
  color: #073a5f !important;
  border-radius: 50px !important;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CookiebotWidget-btn-withdraw{
  background-color: transparent !important;
  border: 2px solid #98f5c4 !important;
  border-radius: 50px !important;
  color: #073a5f !important;
}
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a,
#CookiebotWidget .CookiebotWidget-consent-details button {
  color: #073a5f !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
  border-bottom: 1px solid #98f5c4 !important;
  border-bottom-width: 1px;
  color: #073a5f !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: #073a5f !important;
}
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog
  #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: #494949 !important;
}
#CybotCookiebotDialogTabContent
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #98f5c4 !important;
}
#CybotCookiebotDialogNav
  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid #007196 !important;
  border-bottom-width: 1px;
  color: #007196 !important;
}
#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

#CybotCookiebotDialogNav
  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid #98f5c4 !important;
  border-bottom-color: rgb(0, 113, 150);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-width: 1px;
  color: #073a5f !important;
}
#CybotCookiebotDialog
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog
  .CookieCard
  .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes
  .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: #073a5f !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg{
  fill:#98f5c4 !important;
}