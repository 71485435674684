//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//    5. Other Globals
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

:root {
  --bs-border-radius: 5px;
  --bs-btn-close-color: #124a72;
}

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #124a72; // The default text color

$primary: #124a72;
$secondary: #e8edf1;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$lightgray: #f4f4f4;
/* $dark:       #212529; */
$dark: #1d1d1b;
$nightblue:#073a5f;

$primary-btn: #073a5f;
$primary-btn-hover: #bfcb43;



//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//---------------------------------------------
// 5. Other Globals
//---------------------------------------------
$sectionPaddingTop: 6.25rem;
$sectionPaddingBottom: 6.25rem;